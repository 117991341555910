<template>
  <div>
  <b-skeleton-wrapper
    v-bind="$attrs"
    v-on="$listeners">
    <template #loading>
      <slot name="loading">
        <b-skeleton width="85%"></b-skeleton>
        <b-skeleton width="55%"></b-skeleton>
        <b-skeleton width="70%"></b-skeleton>
      </slot>
    </template>

    <template #default>
      <slot></slot>
    </template>
  </b-skeleton-wrapper>
  </div>
</template>

<script>

export default {
  name: 'SkeletonWrapper',
};

</script>
