<template>
  <div>
    <b-pagination
      align="center"
      v-if="computedItems.length > perPage"
      v-model="currentPage"
      :total-rows="computedItems.length"
      :per-page="perPage"
      aria-controls="issuedInvoicesTable" />
    <b-table
      id="issuedInvoicesTable"
      small
      striped
      hover
      :per-page="perPage"
      :current-page="currentPage"
      empty-text="Nu este înregistrată nicio factură"
      :items="computedItems"
      :fields="tableFields"
      :no-local-sorting="true"
      show-empty>
      <template v-slot:cell(invoiceNumber)="row">
        {{row.value}}
        <span v-if="row.item.clientOrderCode">
          <br>
          <span>{{row.item.clientOrderCode}}</span>
          <router-link :to="{ name: 'clientOrderDetails', params: { clientOrderId: row.item.clientOrderId } }">
            <i class="fas fa-link ml-1 text-smaller" v-b-tooltip.hover.bottom="'Vezi comanda'"/>
          </router-link>
        </span>
      </template>
      <template v-slot:cell(issuedByFullName)="row">
        <user-name-badge :user-name="row.item.issuedBy" />
      </template>
      <template v-slot:cell(statusCode)="row">
        {{(row.item.isCanceled ? 'CANCELED' : 'ISSUED') | displayInvoiceStatus}}
      </template>
      <template v-slot:cell(invoiceLinesValue)="row">
        {{ row.item.invoiceLinesValue | displayAsDecimal(2) }}
      </template>
      <template v-slot:cell(invoiceLinesVatValue)="row">
        {{ row.item.invoiceLinesVatValue | displayAsDecimal(2) }}
      </template>
      <template v-slot:cell(invoiceLinesValueWithVat)="row">
        {{ row.item.invoiceLinesValue + row.item.invoiceLinesVatValue | displayAsDecimal(2) }}
      </template>
      <template v-slot:cell(clientName)="row">
        <span>
          <span>{{row.value}}</span>
          <a href="#"
            class="ml-1"
            v-if="row.item.clientCode"
            @click="showClientDetails(row.item.clientCode)"
            v-b-tooltip.hover.bottom="'Detalii client'">
            <i class="fas fa-info-circle" />
          </a>
        </span>
        <span v-if="row.item.clientCode">
          <br>
          <b-badge
           variant="secondary"
           class="badge-lg"
           v-b-tooltip.hover.bottom="'Cod client'">
           <i class="fas fa-briefcase mr-1" />
           {{row.item.clientCode}}
          </b-badge>
        </span>
      </template>
      <template v-slot:cell(actions)="row">
        <b-button
          size="sm"
          class="ml-1"
          :to="{name: 'issuedInvoiceDetails', params: { issuedInvoiceId: row.item.issuedInvoiceId } }"
          v-b-tooltip.hover title="Vezi documentul">
          <i class="fas fa-eye" />
        </b-button>
        <b-button
          size="sm"
          @click="showDownloadPdfModal(row.item)"
          v-if="row.item.statusCode !== 'DRAFT'"
          v-b-tooltip.hover title="Descarcă PDF">
         <i class="fas fa-file-invoice-dollar" />
        </b-button>
      </template>
    </b-table>
    <b-pagination
      align="center"
      v-if="computedItems.length > perPage"
      v-model="currentPage"
      :total-rows="computedItems.length"
      :per-page="perPage"
      aria-controls="issuedInvoicesTable" />

    <download-invoice-modal
      ref="downloadInvoiceModal" />
  </div>
</template>

<script>
import BigNumber from 'bignumber.js';
import UserNameBadge from './UserNameBadge.vue';
import DownloadInvoiceModal from './DownloadInvoiceModal.vue';

const { DateTime } = require('luxon');

export default {
  name: 'ClientDetailsIssuedInvoicesList',
  components: {
    UserNameBadge,
    DownloadInvoiceModal,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      tableFields: [
        {
          key: 'invoiceNumber',
          label: 'Număr factură / Comandă',
        },
        {
          key: 'issuingDate',
          label: 'Data emitere',
          formatter(val) {
            return DateTime.fromISO(val).toLocaleString(DateTime.DATE_SHORT);
          },
          orderByDirection: 'desc',
        },
        {
          key: 'statusCode',
          label: 'Stare',
        },
        {
          key: 'invoiceLinesValue',
          label: 'Val. f. TVA',
        },
        {
          key: 'invoiceLinesVatValue',
          label: 'Val. TVA',
        },
        {
          key: 'invoiceLinesValueWithVat',
          label: 'Val. cu TVA',
        },
        {
          key: 'clientDeliveryAddressName',
          label: 'Adr. livrare',
        },
        {
          key: 'issuedByFullName',
          label: 'Emis de',
        },
        {
          key: 'actions',
          label: '',
        },
      ],
    };
  },
  filters: {
    displayAsDecimal(value, decimals = 2) {
      return `${new BigNumber(value).decimalPlaces(decimals).toString()}`;
    },
    displayInvoiceStatus(value) {
      if (value === 'ISSUED') return 'emisă';
      if (value === 'CANCELED') return 'anulată';
      return value;
    },
  },
  props: {
    items: [],
  },
  computed: {
    computedItems() {
      return Array.isArray(this.items) ? this.items : [];
    },
  },
  methods: {

    showDownloadPdfModal(item) {
      this.$nextTick(() => this.$refs.downloadInvoiceModal.showModal({ issuingYear: item.issuingYear, invoiceIndex: item.invoiceIndex }, 'FAC'));
    },
  },
};
</script>
