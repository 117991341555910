<template>
  <div>
    <b-pagination
      align="center"
      v-if="computedItems.length > perPage"
      v-model="currentPage"
      :total-rows="computedItems.length"
      :per-page="perPage"
      aria-controls="ordersListTable" />
    <b-table
      id="ordersListTable"
      small
      striped
      hover
      :per-page="perPage"
      :current-page="currentPage"
      empty-text="Nu este înregistrată nicio comandă"
      :items="computedItems"
      :fields="tableFields"
      :no-local-sorting="true"
      show-empty>
      <template v-slot:cell(clientOrderCode)="row">
        {{row.value}}
        <span v-if="row.item.clientSystemOrderIdentifier">
        <br>
        <b-badge
          variant="secondary"
          class="badge-lg"
          v-b-tooltip.hover.bottom="'Cod comandă în sistemul clientului'">
          <i class="fas fa-hashtag" />
          {{row.item.clientSystemOrderIdentifier}}
        </b-badge>
        </span>
      </template>
      <template v-slot:cell(createdByFullName)="row">
        <user-name-badge :user-name="row.value" />
      </template>
      <template v-slot:cell(statusCode)="row">
        <client-order-status-badge :status-code="row.value" />
        <span class="text-uppercase ml-1" v-if="row.item.hasIssuedInvoices">
        <b-badge variant="success">
            <span>Facturată</span>
            <i class="fas fa-file-download pl-1" v-if="row.item.hasExportedInvoices" />
        </b-badge>
        </span>
        <span class="text-uppercase ml-1" v-if="row.item.hasIssuedDeliveryNotes">
        <b-badge variant="success">
            <span>Avizată</span>
        </b-badge>
        </span>
      </template>
      <template v-slot:cell(invoiceLinesValue)="row">
        {{ row.item.invoiceLinesValue | displayAsDecimal(2) }}
      </template>
      <template v-slot:cell(actions)="row">
        <b-button
        size="sm"
        :to="{name: 'clientOrderDetails', params: { clientOrderId: row.item.clientOrderId } }"
        v-if="row.item.statusCode === ClientOrderStatus.DRAFT"
        v-b-tooltip.hover title="Vezi comanda">
          <i class="fas fa-edit" />
        </b-button>
        <b-button
        size="sm"
        :to="{name: 'clientOrderDetails', params: { clientOrderId: row.item.clientOrderId } }"
        v-if="row.item.statusCode !== ClientOrderStatus.DRAFT"
        v-b-tooltip.hover title="Vezi comanda">
          <i class="fas fa-eye" />
        </b-button>
      </template>
    </b-table>
    <b-pagination
      align="center"
      v-if="computedItems.length > perPage"
      v-model="currentPage"
      :total-rows="computedItems.length"
      :per-page="perPage"
      aria-controls="ordersListTable" />
  </div>
</template>

<script>
import BigNumber from 'bignumber.js';
import ClientOrderStatusBadge from './ClientOrderStatusBadge.vue';
import UserNameBadge from './UserNameBadge.vue';

import { ClientOrderStatus } from '../constants';

const { DateTime } = require('luxon');

export default {
  name: 'ClientDetailsOrdersList',
  components: {
    ClientOrderStatusBadge,
    UserNameBadge,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      ClientOrderStatus,
      tableFields: [
        {
          key: 'clientOrderCode',
          label: 'Cod comandă',
        },
        {
          key: 'createdAt',
          label: 'Creat',
          formatter(val) {
            return DateTime.fromISO(val).toLocaleString(DateTime.DATETIME_SHORT);
          },
          orderByDirection: 'desc',
        },
        {
          key: 'statusCode',
          label: 'Stare',
        },
        {
          key: 'orderLinesValue',
          label: 'Val. produse',
        },
        {
          key: 'invoiceLinesValue',
          label: 'Val. facturi',
        },
        {
          key: 'clientDeliveryAddressName',
          label: 'Adr. livrare',
        },
        {
          key: 'createdByFullName',
          label: 'Creat de',
        },
        {
          key: 'actions',
          label: '',
        },
      ],
    };
  },
  filters: {
    displayAsDecimal(value, decimals = 2) {
      return `${new BigNumber(value).decimalPlaces(decimals).toString()}`;
    },
  },
  props: {
    items: [],
  },
  computed: {
    computedItems() {
      return Array.isArray(this.items) ? this.items : [];
    },
  },
};
</script>
