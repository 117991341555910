<template>
  <b-container fluid v-if="isLoaded">
    <div v-if="!hasError">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap">
        <h2>{{ clientDetailsTitleText }}</h2>
        <b-button-toolbar class="pb-3">
          <super-button variant="primary" class="mx-1 p-2" id="createClientOrder" :to="{ name: 'clientOrderCreate', query: { clientCode } }"><i class="fas fa-plus-square"/>
            <span class="d-none d-lg-inline ml-1">Comandă nouă</span>
          </super-button>
          <super-button class="mx-1 p-2" id="navigateBackToolbarButton" :to="lastRoute || { name: 'client' }"><i class="fas fa-caret-left"/>
            <span class="d-none d-lg-inline ml-1">Înapoi</span>
          </super-button>
        </b-button-toolbar>
        <b-tooltip target="createClientOrder" placement="topleft" custom-class="d-lg-none">Comandă nouă</b-tooltip>
        <b-tooltip target="navigateBackToolbarButton" placement="topleft" custom-class="d-lg-none">Înapoi</b-tooltip>
      </div>
      <div v-if="shouldDisplay" class="container-fluid">
        <b-container fluid>
          <b-row>
            <b-col xs="12" sm="3" md="1" class="text-left text-sm-right"><strong>Nume:</strong></b-col>
            <b-col xs="12" sm="9" md="11" class="text-left">{{ details.nume }}</b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="3" md="1" class="text-left text-sm-right"><strong>Cod fiscal:</strong></b-col>
            <b-col xs="12" sm="9" md="11" class="text-left">{{ clientCode }}</b-col>
          </b-row>
          <b-row v-if="details.numarInregistrareRC">
            <b-col xs="12" sm="3" md="1" class="text-left text-sm-right"><strong>Numar RC:</strong></b-col>
            <b-col xs="12" sm="9" md="11" class="text-left">{{ details.numarInregistrareRC }}</b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="3" md="1" class="text-left text-sm-right"><strong>Adresă:</strong></b-col>
            <b-col xs="12" sm="9" md="11" class="text-left">{{ clientDetailsFullAddress }}</b-col>
          </b-row>
          <b-row v-if="details.adresaTelefon">
            <b-col xs="12" sm="3" md="1" class="text-left text-sm-right"><strong>Telefon:</strong></b-col>
            <b-col xs="12" sm="9" md="11" class="text-left">{{ details.adresaTelefon }}</b-col>
          </b-row>
          <b-row v-if="details.adresaFax">
            <b-col xs="12" sm="3" md="1" class="text-left text-sm-right"><strong>Fax:</strong></b-col>
            <b-col xs="12" sm="9" md="11" class="text-left">{{ details.adresaFax }}</b-col>
          </b-row>
          <b-row v-if="details.adresaEmail">
            <b-col xs="12" sm="3" md="1" class="text-left text-sm-right"><strong>Email:</strong></b-col>
            <b-col xs="12" sm="9" md="11" class="text-left">{{ details.adresaEmail }}</b-col>
          </b-row>
        </b-container>

        <!-- Sectiune adrese de livrare -->
        <h3 class="mt-2 d-flex flex-column align-items-start flex-wrap flex-md-nowrap">Adrese livrare</h3>
        <skeleton-wrapper class="w-100" :loading="clientDeliveryAddressesTableBusy">
          <b-alert v-if="clientDeliveryAddressesQueryError" show variant="danger">Eroare la încărcarea adreselor de livrare</b-alert>
          <client-details-delivery-addresses-list class="w-100" :items="clientDeliveryAddressesItems"> </client-details-delivery-addresses-list>
        </skeleton-wrapper>

        <!-- Sectiune comenzi -->
        <h3 class="mt-2 d-flex flex-column align-items-start flex-wrap flex-md-nowrap">Comenzi</h3>
        <skeleton-wrapper class="w-100" :loading="clientOrdersTableBusy">
          <b-alert v-if="clientOrderQueryError" show variant="danger">Eroare la încărcarea comenzilor</b-alert>
          <client-details-orders-list class="w-100" :items="clientOrderItems" />
        </skeleton-wrapper>

        <!-- Sectiune facturi -->
        <h3 class="mt-2 d-flex flex-column align-items-start flex-wrap flex-md-nowrap">Facturi</h3>
        <skeleton-wrapper class="w-100" :loading="issuedInvoicesTableBusy">
          <b-alert v-if="issuedInvoicesQueryError" show variant="danger">Eroare la încărcarea facturilor</b-alert>
          <client-details-issued-invoices-list v-else class="w-100" :items="issuedInvoicesItems" />
        </skeleton-wrapper>
      </div>
    </div>
    <div v-else>
      <h2>Eroare la încărcare</h2>
      <b-button type="button" variant="danger" @click="reloadPage" :disabled="xhrRequestRunning" class="mx-1 p-2 mb-2">
        <span class="d-none d-sm-inline">&nbsp;Reîncarcă</span>
      </b-button>
      <b-alert :show="hasError" variant="danger">{{ errorMessage }}</b-alert>
    </div>
  </b-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import ClientDetailsOrdersList from '../components/ClientDetailsOrdersList.vue';
import ClientDetailsDeliveryAddressesList from '../components/ClientDetailsDeliveryAddressesList.vue';
import ClientDetailsIssuedInvoicesList from '../components/ClientDetailsIssuedInvoicesList.vue';
import SkeletonWrapper from '../components/SkeletonWrapper.vue';

export default {
  name: 'ClientDetails',
  components: {
    ClientDetailsOrdersList,
    ClientDetailsDeliveryAddressesList,
    ClientDetailsIssuedInvoicesList,
    SkeletonWrapper,
  },
  data() {
    return {
      clientCode: '',
      shouldDisplay: false,
      hasError: false,
      errorMessage: '',
      isLoaded: false,

      // client order table variables
      clientOrderItems: [],
      clientOrdersTableBusy: false,
      clientOrderQueryError: false,
      clientOrdersOrderBy: 'clientOrderCode',
      clientOrdersSortDesc: true,

      // issued invoices table variables
      issuedInvoicesItems: [],
      issuedInvoicesTableBusy: false,
      issuedInvoicesQueryError: false,
      issuedInvoicesOrderBy: 'issuingDate',
      issuedInvoicesSortDesc: true,

      // delivery addresses table variables
      clientDeliveryAddressesItems: [],
      clientDeliveryAddressesTableBusy: false,
      clientDeliveryAddressesQueryError: false,
      clientDeliveryAddressesOrderBy: 'adresaLivrarePartenerId',
      clientDeliveryAddressesSortDesc: true,

      currentPage: 1,
      numPages: 1,
      perPage: 1000,
      sortDesc: true,
      details: {
        nume: '',
        codPartener: '',
        adresaStrada: '',
        adresaCodPostal: '',
        adresaLocalitate: '',
        adresaCodJudet: '',
        adresaCodTara: '',
        adresaTelefon: '',
      },
    };
  },
  computed: {
    ...mapState(['xhrRequestRunning', 'lastRoute']),
    clientDetailsFullAddress() {
      const fullAddressParts = [];
      if (this.details.adresaStrada) { fullAddressParts.push(this.details.adresaStrada); }
      if (this.details.adresaLocalitate) { fullAddressParts.push(this.details.adresaLocalitate); }
      if (this.details.adresaJudet) { fullAddressParts.push(this.details.adresaJudet); }
      if (this.details.adresaCodPostal) { fullAddressParts.push(this.details.adresaCodPostal); }
      if (this.details.adresaTara) { fullAddressParts.push(this.details.adresaTara); }

      return fullAddressParts.join(', ');
    },
    clientDetailsTitleText() {
      return this.shouldDisplay
        ? `Detalii partener${this.details ? ` - ${this.details.nume}` : ''}`
        : 'Nu există partener';
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // eslint-disable-next-line no-param-reassign
      vm.clientCode = to.params.clientCode;
      vm.getClientDetails();
      vm.performClientOrdersLookupInternal();
      vm.performIssiedInvoicesLookupInternal();
      vm.performClientDeliveryAddressesLookupInternal();
    });
  },
  beforeRouterUpdate(to, from, next) {
    this.clientCode = to.params.clientCode;
    this.getClientDetails();
    next();
  },
  created() {
    this.clientCode = this.$route.params.clientCode;
    this.getClientDetails();
  },
  methods: {
    ...mapActions([
      'performClientDetailsQuery',
      'performClientOrdersQuery',
      'performIssuedInvoicesQuery',
      'performClientDeliveryAddressesQuery',
    ]),
    async getClientDetails() {
      this.isLoaded = false;
      try {
        const result = await this.performClientDetailsQuery(this.clientCode);
        this.details = result || this.details;
        this.shouldDisplay = !!result;
        this.hasError = false;
      } catch (error) {
        this.hasError = true;
        this.errorMessage = error.response.data;
      } finally {
        this.isLoaded = true;
      }
    },
    serializeFilters() {
      const filters = {
        client: {
          val: this.clientCode,
          op: '=',
        },
      };
      return JSON.stringify(Object.keys(filters).map((key) => ({
        field: key,
        op: filters[key].op,
        val: filters[key].val,
      })).filter((filter) => typeof filter.val !== 'undefined' && filter.val !== null && (!Array.isArray(filter.val) || filter.val.length > 0)));
    },
    async performClientOrdersLookupInternal() {
      this.clientOrdersTableBusy = true;

      let result;
      try {
        result = await this.performClientOrdersQuery({
          page: this.currentPage,
          perPage: this.perPage,
          orderBy: this.clientOrdersOrderBy,
          orderByDirection: this.clientOrdersSortDesc ? 'd' : 'a',
          queryTerm: this.queryTerm ? this.queryTerm : undefined,
          filters: this.serializeFilters(),
        });
        this.clientOrderQueryError = false;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        this.clientOrderQueryError = true;

        result = {
          items: [],
          count: 0,
          limit: this.perPage,
          pages: 0,
        };
      }

      this.clientOrderItems = result.items;

      this.clientOrdersTableBusy = false;
    },
    async performIssiedInvoicesLookupInternal() {
      this.issuedInvoicesTableBusy = true;

      const serializedFilters = this.serializeFilters();

      let result;
      try {
        result = await this.performIssuedInvoicesQuery({
          page: this.currentPage,
          perPage: this.perPage,
          orderBy: this.issuedInvoicesOrderBy,
          orderByDirection: this.issuedInvoicesSortDesc ? 'd' : 'a',
          queryTerm: this.queryTerm ? this.queryTerm : undefined,
          filters: serializedFilters,
        });
        this.issuedInvoicesQueryError = false;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        this.issuedInvoicesQueryError = true;

        result = {
          items: [],
          count: 0,
          limit: this.perPage,
          pages: 0,
        };
      }

      this.issuedInvoicesItems = result.items;

      this.issuedInvoicesTableBusy = false;
    },
    async performClientDeliveryAddressesLookupInternal() {
      this.clientDeliveryAddressesTableBusy = true;

      let result;
      try {
        result = await this.performClientDeliveryAddressesQuery({
          clientCode: this.clientCode,
          page: this.currentPage,
          perPage: this.perPage,
          orderBy: this.clientDeliveryAddressesOrderBy,
          orderByDirection: this.clientDeliveryAddressesSortDesc ? 'd' : 'a',
        });
        this.clientDeliveryAddressesQueryError = false;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        this.clientDeliveryAddressesQueryError = true;

        result = {
          items: [],
          count: 0,
          limit: this.perPage,
          pages: 0,
        };
      }

      this.clientDeliveryAddressesItems = result.items.map((obj) => {
        const arr = [];
        if (obj.adresa) { arr.push(obj.adresa); }
        if (obj.localitate) { arr.push(obj.localitate); }
        if (obj.judet) { arr.push(obj.judet); }
        if (obj.tara) { arr.push(obj.tara); }
        return ({ ...obj, fullAddress: arr.join(', ') });
      });

      this.clientDeliveryAddressesTableBusy = false;
    },
    reloadPage() {
      this.$router.go(this.$router.currentRoute);
    },
  },
};
</script>
