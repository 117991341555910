<template>
  <b-container fluid>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap">
      <h2>Catalog clienți</h2>
    </div>
    <b-row class="justify-content-center">
      <b-col col sm="8" md="6" lg="4">
        <b-form @submit.stop.prevent="onSubmit">
          <b-form-group>
            <b-input-group>
              <b-form-input id="lookupTerm" v-model="lookupTerm" trim autofocus placeholder="Caută după cod sau denumire client (minim 2 caractere)"></b-form-input>
              <b-input-group-append>
                <b-button type="submit" variant="primary" :disabled="xhrRequestRunning">
                  <i class="fas fa-search"/><span class="d-none d-lg-inline">&nbsp;Caută</span>
                </b-button>
                <b-button type="button" variant="info" :disabled="xhrRequestRunning" @click="showClientCreateModal">
                  <i class="fas fa-plus"/>
                  <span class="d-none d-lg-inline">&nbsp;Client nou</span>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-form>
      </b-col>
    </b-row>
    <br>
    <div>
      <b-alert :show="!lookupPerformed" variant="info">Introdu un termen de căutare (cod sau denumire client)</b-alert>
      <b-alert :show="!itemsAvailable && lookupPerformed && !lookupInProgress" variant="warning">Nu a fost gasit niciun rezultat pentru termenul "{{lastLookupTerm}}"</b-alert>
      <b-alert :show="lookupInProgress" variant="info">Se cauta termenul "{{lookupInProgressTerm}}"</b-alert>
      <b-alert :show="lookupFailed" variant="danger">A intervent o eroare la cautare pentru termenul "{{lastLookupTerm}}"</b-alert>
      <b-alert :show="itemsAvailable && lookupPerformed && !lookupInProgress" variant="success">Au fost gasite {{items.length}} rezultate pentru termenul "{{lastLookupTerm}}"</b-alert>
    </div>
    <b-table
      striped
      hover
      small
      thead-class="text-small"
      tbody-class="text-small"
      tfoot-class="text-small"
      v-if="itemsAvailable && lookupPerformed"
      :busy.sync="lookupInProgress"
      :items="items"
      :fields="tableFields">
      <template v-slot:cell(clientCode)="data">
        <Highlighter class="my-highlight"
          highlightClassName="highlight"
          :searchWords="highlightValue"
          :autoEscape="true"
          :textToHighlight="data.value"/>
      </template>
      <template v-slot:cell(clientName)="data">
        <Highlighter class="my-highlight"
          highlightClassName="highlight"
          :searchWords="highlightValue"
          :autoEscape="true"
          :textToHighlight="data.value"/>
      </template>
      <template v-slot:cell(actions)="row">
        <b-button
          size="sm"
          :to="{name: 'clientDetails', params: { clientCode: row.item.clientCode } }"
          v-b-tooltip.hover title="Vezi set produse">
         <i class="fas fa-eye" />
        </b-button>
      </template>
    </b-table>
    <client-details-modal :usage="'CREATE'" ref="clientDetailsModal" :callback="clientCreateModalCallback"/>
  </b-container>
</template>

<script>
import Highlighter from 'vue-highlight-words';
import { mapState, mapActions } from 'vuex';
import ClientDetailsModal from '../components/ClientDetailsModal.vue';

export default {
  name: 'Clients',
  components: {
    Highlighter,
    ClientDetailsModal,
  },
  data() {
    return {
      lookupTerm: null,
      lastLookupTerm: null,
      lookupInProgress: false,
      lookupInProgressTerm: null,
      lookupPerformed: false,
      lookupFailed: false,
      tableFields: [
        {
          key: 'clientCode',
          label: 'Cod client',
        },
        {
          key: 'clientName',
          label: 'Denumire client',
        },
        {
          key: 'actions',
          label: '',
        },
      ],
      items: [],
    };
  },
  computed: {
    itemsAvailable() { return this.items.length; },
    // eslint-disable-next-line no-mixed-operators
    highlightValue() { return this.lastLookupTerm && [this.lastLookupTerm] || []; },
    ...mapState(['xhrRequestRunning']),
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
    // eslint-disable-next-line no-param-reassign,no-mixed-operators
      vm.lookupTerm = to.query.lookup;
      vm.performLookup();
    });
  },
  async beforeRouteUpdate(to, from, next) {
    this.lookupTerm = to.query.lookup;
    try {
      await this.performLookup();
    } finally {
      next();
    }
  },
  methods: {
    ...mapActions(['performClientLookup']),
    async performLookup() {
      if (!this.lookupTerm || this.lookupTerm.length < 2) return;

      this.lookupPerformed = true;
      this.lookupInProgress = true;
      this.lookupInProgressTerm = this.lookupTerm;

      let result;
      let errorState = false;
      try {
        result = await this.performClientLookup({
          lookupTerm: this.lookupInProgressTerm,
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        errorState = true;

        result = [];
      }

      if (!errorState) {
        this.lastLookupTerm = this.lookupInProgressTerm;
      }

      this.lookupInProgressTerm = null;
      this.lookupFailed = errorState;
      this.lookupInProgress = false;
      this.items = result;
    },
    async onSubmit() {
      if (!this.lookupTerm || this.lookupTerm.length < 2) return;

      this.$router.push({ query: { lookup: this.lookupTerm } });
    },
    showClientCreateModal(evt) {
      evt.preventDefault();

      this.$refs.clientDetailsModal.showModal();
    },
    clientCreateModalCallback(client) {
      this.$refs.clientDetailsModal.hideModal();
      if (client) {
        if (this.lookupTerm) {
          this.$router.go(this.$router.currentRoute);
        }
      }
    },
  },
};
</script>
