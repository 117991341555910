<template>
  <div>
    <b-pagination
      align="center"
      v-if="computedItems.length > perPage"
      v-model="currentPage"
      :total-rows="computedItems.length"
      :per-page="perPage"
      aria-controls="deliveryAddressesListTable" />
    <b-table
      id="deliveryAddressesListTable"
      small
      striped
      hover
      :per-page="perPage"
      :current-page="currentPage"
      empty-text="Nu este înregistrată nicio adresa de livrare"
      :items="computedItems"
      :fields="tableFields"
      :no-local-sorting="true"
      show-empty>
    </b-table>
    <b-pagination
      align="center"
      v-if="computedItems.length > perPage"
      v-model="currentPage"
      :total-rows="computedItems.length"
      :per-page="perPage"
      aria-controls="deliveryAddressesListTable" />
  </div>
</template>

<script>

export default {
  name: 'ClientDetailsDeliveryAddressesList',
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      tableFields: [
        {
          key: 'codInternPartener',
          label: 'Cod',
        },
        {
          key: 'denumire',
          label: 'Denumire',
        },
        {
          key: 'fullAddress',
          label: 'Adresa',
        },
      ],
    };
  },
  props: {
    items: [],
  },
  computed: {
    computedItems() {
      return Array.isArray(this.items) ? this.items : [];
    },
  },
};
</script>
