<template>
  <b-badge :variant="getBadgeVariant(statusCode)"><span class="text-uppercase">{{translatedStatusCode}}</span></b-badge>
</template>

<script>
import { ClientOrderStatus } from '../constants';

export default {
  name: 'ClientOrderStatusBadge',
  props: {
    statusCode: String,
  },
  computed: {
    translatedStatusCode() {
      if (this.statusCode === ClientOrderStatus.DRAFT) return 'ciornă';
      if (this.statusCode === ClientOrderStatus.SUBMITTED) return 'trimisă';
      if (this.statusCode === ClientOrderStatus.PREPARING) return 'în pregătire';
      if (this.statusCode === ClientOrderStatus.PREPARED) return 'pregătită';
      if (this.statusCode === ClientOrderStatus.COMPLETED) return 'finalizată';
      if (this.statusCode === ClientOrderStatus.CANCELED) return 'anulată';
      return this.statusCode;
    },
  },
  methods: {
    getBadgeVariant(orderStatusCode) {
      if (orderStatusCode === ClientOrderStatus.DRAFT) return 'primary';
      if (orderStatusCode === ClientOrderStatus.SUBMITTED) return 'warning';
      if (orderStatusCode === ClientOrderStatus.PREPARING) return 'danger';
      if (orderStatusCode === ClientOrderStatus.PREPARED) return 'secondary';
      if (orderStatusCode === ClientOrderStatus.COMPLETED) return 'success';
      if (orderStatusCode === ClientOrderStatus.CANCELED) return 'light';
      return 'default';
    },
  },
};
</script>
